.CarouselMain{
    padding: 20px;
}
.CarouselTitle{
    font-size: 40px;
}
.CarouselSubTitle{
    font-size: 20px;
}
.p-carousel-container{
    background-color: #B6C454;
}
.p-carousel-indicators{
    background-color: #B6C454;
}