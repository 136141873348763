.PageContent {
    padding: 20px;
}
.InfoSection{
    width: 250px;
    padding: 10px;
    text-align: justify;
}
.CircleIcon {
    height: 100px;
    width: 100px;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CircleStyle{
    display:flex;
    justify-content: center;
    align-items: center;
}
.Container {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
}
