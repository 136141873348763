.PageContent {
    padding: 10px;
}
.PageHeaderProjects {
    background-color: #62b1e6 !important;
    height: 150px;
    padding: 40px;
    font-size: 40px;
}
.Sections {
    width: 250px;
    padding: 20px;
}
.SectionHeader {
    text-align: center;
}
.SectionText {
    text-align: justify;
}
.SectionFooter {
    text-align: center;
}
.SectionFooter a {
    text-decoration: none;
    color: blue;
}