.p-menubar {
    background-color: #AF9164 !important;
    color: #000000 !important;
  }
  
  .p-menuitem-text{
    color: #000000 !important;
  }
  
  .p-menuitem{
    background: #AF9164 !important;
  }

  .p-menuitem-link:hover {
    background: #AF9164 !important;
  }

  .p-menuitem-icon{
    color: #000000 !important;
  }
